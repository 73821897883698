import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUtensils, FaUsers, FaChartLine, FaHandshake, FaStore } from 'react-icons/fa';
import './AboutUs.scss';
import backgroundImage from '../../Images/manage_team2.jpeg'; // Use one of your images as background

const expertiseItems = [
  { icon: <FaUtensils />, text: "Comprehensive hospitality solutions" },
  { icon: <FaUsers />, text: "Experienced industry professionals" },
  { icon: <FaChartLine />, text: "Innovative management strategies" },
  { icon: <FaHandshake />, text: "Reliable partnership for success" },
  { icon: <FaStore />, text: "Revolutionizing F&B establishment operations" },
];

const AboutUs = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.about-us');
      if (element) {
        const rect = element.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight && rect.bottom >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="about-us">
      <div className="background-watermark" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        About Us
      </motion.h1>
      <div className="about-content">
        <motion.div 
          className="main-content"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Narasimha Enterprises Hospitality was founded on a comprehensive understanding of the hospitality industry's diverse needs. 
            We recognised that owners and operators needed more than just staff - they required a reliable partner who could handle 
            the complexities of running successful establishments across various formats.<br /><br />
            Our journey began with a passion for hospitality and a deep understanding of the challenges faced by business owners in this sector. 
            We saw an opportunity to revolutionize how food and beverage establishments are set up, staffed, and managed, and thus, 
            Narasimha Enterprises Hospitality was born.
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.3, duration: 0.5 }}
          >
            From cozy cafes to bustling hotels, we transform visions into thriving realities. Our team of industry veterans brings
            extensive experience to every project, ensuring excellence in all aspects of hospitality management. At Narasimha Enterprises,
            we believe that the right management approach can elevate good businesses to greatness. Let us be the catalyst for your
            success in the dynamic world of hospitality.
          </motion.p>
        </motion.div>
        <AnimatePresence>
          {isVisible && (
            <motion.div 
              className="expertise-section"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              <h2>Our Expertise:</h2>
              <ul>
                {expertiseItems.map((item, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ delay: 0.8 + index * 0.1, duration: 0.3 }}
                  >
                    <span className="icon">{item.icon}</span>
                    {item.text}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default AboutUs;