import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Intro.scss';
import image1 from './IntroImages/1.jpeg';
import image2 from './IntroImages/2.png';
import image3 from './IntroImages/3.png';
import image4 from './IntroImages/4.png';
import image5 from './IntroImages/5.png';
import image6 from './IntroImages/6.png';
import image7 from './IntroImages/7.png';
import image8 from './IntroImages/8.png';

// Array of image objects with src and quote properties
const images = [
    { src: image1, quote: "Where every meal is a celebration of flavor and hospitality." },
    { src: image2, quote: "Crafting culinary experiences with passion and precision." },
    { src: image3, quote: "Good food is the foundation of genuine happiness." },
    { src: image4, quote: "United in service, committed to excellence" },
    { src: image5, quote: "Your home away from home, where comfort meets luxury" },
    { src: image6, quote: "Mixing moments of joy, one drink at a time" },
    { src: image7, quote: "Creating unforgettable moments under the stars" },
    { src: image8, quote: "Savoring success: Where culinary excellence meets financial growth" }
];

const Intro = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [direction, setDirection] = useState(1);

    // Function to go to the previous slide
    const goToPrevious = useCallback(() => {
        setDirection(-1);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }, []);

    // Function to go to the next slide
    const goToNext = useCallback(() => {
        setDirection(1);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, []);

    // Effect to automatically change slides every 5 seconds
    useEffect(() => {
        const timer = setInterval(goToNext, 5000);
        return () => clearInterval(timer);
    }, [goToNext]);

    return (
        <div className="carousel-container">
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={currentIndex}
                    className="carousel-slide"
                    custom={direction}
                    initial={{ x: direction > 0 ? '100%' : '-100%', opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: direction > 0 ? '-100%' : '100%', opacity: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img 
                        src={images[currentIndex].src} 
                        alt={`Narasimha Enterprises Hospitality: ${images[currentIndex].quote}`} 
                        className="carousel-image" 
                    />
                    <AnimatePresence>
                        {isHovered && (
                            <motion.div 
                                className="quote-overlay"
                                initial={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 100 }}
                                transition={{ duration: 0.5, ease: "easeOut" }}
                            >
                                <p>{images[currentIndex].quote}</p>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <button className="carousel-btn prev" onClick={goToPrevious} aria-label="Previous slide">
                        &lt;
                    </button>
                    <button className="carousel-btn next" onClick={goToNext} aria-label="Next slide">
                        &gt;
                    </button>
                </motion.div>
            </AnimatePresence>
            <div className="slide-counter">
                {currentIndex + 1} / {images.length}
            </div>
        </div>
    );
};

export default Intro;