import React, { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import emailjs from 'emailjs-com';
import './Contact.scss';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_v3w48sv',
      'template_rgq3bm7',
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        to_email: 'info.narasimha.hospitality@gmail.com'
      },
      'fxHD4_NGH0HXTx24h'
    )
    .then((response) => {
      console.log('Email sent successfully!', response.status, response.text);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
    })
    .catch((error) => {
      console.error('Failed to send email:', error);
      setSubmitStatus('error');
    });
  };

  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section ref={sectionRef} className="contact-us">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        Contact Us
      </motion.h1>
      <motion.p
        className="intro-text"
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        If you have any questions or need further information about our services, please feel free to reach out to us. We are here to assist you.
      </motion.p>
      
      <motion.div 
        className="contact-container"
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        <motion.div className="quick-links" variants={itemVariants}>
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#about">About Us</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#team">Our Team</a></li>
            <li><a href="#clients">Our Clients</a></li>
          </ul>
        </motion.div>
        <motion.div className="contact-info" variants={itemVariants}>
          <div className="contact-details">
            <div className="contact-item">
              <h3>Email</h3>
              <p>info.narasimha.hospitality@gmail.com</p>
            </div>
            <div className="contact-item">
              <h3>Phone</h3>
              <p>+91 7505095952</p>
              <p>+91 9967795525</p>
            </div>
            <div className="contact-item">
              <h3>Address</h3>
              <p>Near Group no.7 Auto stand,<br />Tagore Nagar, Vikhroli East<br />Maharashtra-400083.</p>
            </div>
          </div>
        </motion.div>
        <motion.div className="contact-form" variants={itemVariants}>
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                required 
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                required 
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" 
                name="message" 
                required
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
          {submitStatus === 'success' && (
            <div className="success-message">
              Thanks for contacting us! We'll get back to you soon.
            </div>
          )}
          {submitStatus === 'error' && (
            <div className="error-message">
              Sorry, there was an error sending your message. Please try again later.
            </div>
          )}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default ContactUs;