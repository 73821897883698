import React, { useState } from 'react';
import './Header.scss';
import logo from '../../Images/logo.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavClick = (event, sectionId) => {
    event.preventDefault();
    if (window.innerWidth <= 768) {
      toggleMenu(); // Close the menu only on mobile
    }

    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" onClick={(e) => handleNavClick(e, 'intro')} className="logo-image" />
        <h1 className="logo-text">Narasimha Enterprises Hospitality</h1>
      </div>
      <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#about" onClick={(e) => handleNavClick(e, 'about')}>ABOUT US</a></li>
          <li><a href="#services" onClick={(e) => handleNavClick(e, 'services')}>SERVICES</a></li>
          <li><a href="#team" onClick={(e) => handleNavClick(e, 'team')}>OUR TEAM</a></li>
          <li><a href="#clients" onClick={(e) => handleNavClick(e, 'clients')}>OUR CLIENTS</a></li>
          <li className="contact-us-item">
            <a href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>CONTACT US</a>
          </li>
        </ul>
      </nav>
      <button className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Header;

