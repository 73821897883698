// Clients.jsx
import React, { useRef, useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { motion, useInView } from 'framer-motion';
import './Clients.scss';

const importAll = (r) => {
  return r.keys().map((fileName) => ({
    id: fileName.replace('./', '').replace(/\.(png|jpe?g|svg)$/, ''),
    name: fileName.replace('./', '').replace(/\.(png|jpe?g|svg)$/, ''),
    logo: r(fileName)
  }));
};

const images = importAll(require.context('./ClientsImages', false, /\.(png|jpe?g|svg)$/));

const ClientLogo = ({ logo, name }) => (
  <div className="client-logo">
    <img src={logo} alt={name} />
  </div>
);

const Clients = () => {
  const [isMobile, setIsMobile] = useState(false);
  const midpoint = Math.ceil(images.length / 2);
  const firstHalf = images.slice(0, midpoint);
  const secondHalf = images.slice(midpoint);

  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      ref={sectionRef}
      className="clients-section"
      variants={containerVariants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
    >
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        Our Clients
      </motion.h1>
      <motion.p
        className="intro"
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        Our diverse clientele represents the best of the restaurant industry, from fine dining to lively bars and innovative fusion eateries.
        Each partnership showcases our commitment to culinary excellence and innovation. 
        These logos represent the unique dining experiences we've helped create across the region.
      </motion.p>
      
      <motion.div variants={itemVariants}>
        <Marquee
          speed={40}
          pauseOnHover={!isMobile}
          gradient={false}
          className="marquee"
        >
          {[...firstHalf, ...firstHalf].map((client, index) => (
            <ClientLogo
              key={`left-${client.id}-${index}`}
              logo={client.logo}
              name={client.name}
            />
          ))}
        </Marquee>
      </motion.div>
      
      <motion.div variants={itemVariants}>
        <Marquee
          speed={40}
          pauseOnHover={!isMobile}
          gradient={false}
          direction="right"
          className="marquee"
        >
          {[...secondHalf, ...secondHalf].map((client, index) => (
            <ClientLogo
              key={`right-${client.id}-${index}`}
              logo={client.logo}
              name={client.name}
            />
          ))}
        </Marquee>
      </motion.div>
    </motion.div>
  );
};

export default Clients;