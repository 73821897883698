import React from 'react';
import Header from './Components/Header/Header';
import AboutUs from './Components/AboutUs/AboutUs';
import OurServices from './Components/OurServices/OurServices';
import Contact from './Components/Contact/Contact';
import  Clients from './Components/Clients/Clients';

import OurTeam from './Components/OurTeam/OurTeam';
import Intro from './Components/Intro/Intro';
import Footer from './Components/Footer/Footer';
function App() {
  return (

     
      <div>
      <main>
      <Header />
      <section id="intro">
      <Intro />
        </section>
        <section id="about">
          <AboutUs />
        </section>
        <section id="services">
        <OurServices />
        </section>
        <section id="team">
          <OurTeam />
        </section>
        <section id="clients">
        <Clients />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <section id="footer">
          <Footer />
        </section>
      </main>
      </div>

  );
}

export default App;