import React from 'react';
import './Footer.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-content">
      <h2 className="footer-logo">Narasimha Enterprises Hospitality</h2>
      <p>Serving Excellence, Managing Perfection</p>
      <p className="copyright">&copy; {currentYear} Narasimha Enterprises. All rights reserved.</p>
      <p className="credits">Website developed by <a href="https://sitehorizon.in" target="_blank" rel="noopener noreferrer">Team SiteHorizon</a></p>
    </div>
  );
};

export default Footer;