import React, { useState, useEffect,useRef } from 'react';
import { motion,useInView } from 'framer-motion';
import { FaStore, FaUtensils, FaUsers, FaSync, FaBullhorn, FaChartLine, FaTrophy, FaRocket,FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import './OurServices.scss';

const serviceItems = [
  {
    title: "New Openings Setup & Management",
    description: "Complete solutions for launching new food and beverage establishments, from concept development to pre-opening marketing.",
    icon: <FaStore />
  },
  {
    title: "Food and Beverage Management",
    description: "Ensuring smooth and efficient culinary operations with menu design, supplier sourcing, and quality assurance.",
    icon: <FaUtensils />
  },
  {
    title: "Staff Management",
    description: "Implementing SOPs for consistent service excellence, including recruitment, training, and performance monitoring.",
    icon: <FaUsers />
  },
  {
    title: "Business Takeover and Renewal",
    description: "Revitalizing existing operations or taking over management to optimize performance through strategic planning and rebranding.",
    icon: <FaSync />
  },
  {
    title: "Marketing Strategies",
    description: "Helping you stand out with innovative brand development, digital marketing campaigns, and customer engagement initiatives.",
    icon: <FaBullhorn />
  },
  {
    title: "Financial Optimization",
    description: "Maximizing profitability through strategic financial planning, cost control, and revenue management techniques.",
    icon: <FaChartLine />
  },
  {
    title: "Management Excellence",
    description: "Overseeing daily restaurant operations, including inventory control, customer service, and quality assurance for seamless hospitality experiences.",
    icon: <FaTrophy />
  },
  {
    title: "Business Growth Strategies",
    description: "Developing targeted strategies to expand your hospitality business's customer base and market presence.",
    icon: <FaRocket />
  }
];

const OurServices = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const nextService = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceItems.length);
  };

  const prevService = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + serviceItems.length) % serviceItems.length);
  };
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section ref={sectionRef} className="our-services">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        Our Services
      </motion.h1>
      <motion.p
        className="intro"
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        At Narasimha Enterprises Hospitality, we provide a comprehensive range of services tailored to meet the unique needs of the hospitality industry. Our expertise ensures that every aspect of your business is optimized for success. Here are the services we offer:
      </motion.p>
      <motion.div 
        className={`services-list ${isMobile ? 'mobile-view' : ''}`}
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {isMobile ? (
          <>
            <button className="nav-button prev" onClick={prevService}>
              <FaChevronLeft />
            </button>
            <motion.div
              key={currentIndex}
              className="service-item"
              variants={itemVariants}
            >
              <div className="icon">{serviceItems[currentIndex].icon}</div>
              <h3>{serviceItems[currentIndex].title}</h3>
              <p>{serviceItems[currentIndex].description}</p>
            </motion.div>
            <button className="nav-button next" onClick={nextService}>
              <FaChevronRight />
            </button>
          </>
        ) : (
          serviceItems.map((item, index) => (
            <motion.div
              key={index}
              className="service-item"
              variants={itemVariants}
            >
              <div className="icon">{item.icon}</div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </motion.div>
          ))
        )}
      </motion.div>
    </section>
  );
};

export default OurServices;